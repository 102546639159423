import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

export default function LandingPage() {
    const navigate = useNavigate();
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [registerModalVisible, setRegisterModalVisible] = useState(false);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [registerCodeModalVisible, setRegisterCodeVisible] = useState(false);

    const testPasswordConfirm = (password: string, passwordConfirm: string) => {
        return password === passwordConfirm;
    };

    const register = (event: React.FormEvent) => {
        event.preventDefault();
        const registerElements: HTMLInputElement[] = Array.from(document.querySelectorAll<HTMLInputElement>('.register'));
        const registerParams = {
            surname: '',
            lastname: '',
            email: '',
            company: '',
            telephone: '',
            password: '',
            passwordConfirm: ''
        };

        registerElements.forEach(input => registerParams[input.dataset.itype as keyof typeof registerParams] = input.value);

        if (!testPasswordConfirm(registerParams.password, registerParams.passwordConfirm)) {
            alert('Ihre Passwörter stimmen nicht miteinander überein!');
            return;
        }

        if (!termsAccepted) {
            alert('Sie müssen die AGB akzeptieren!');
            return;
        }

        // TODO: Register logic here
    };

    const submitLogin = (event: React.FormEvent) => {
        console.log('loginParams');
        event.preventDefault();
        const loginParams = { email: '', password: '' };
        const loginElements: HTMLInputElement[] = Array.from(document.querySelectorAll<HTMLInputElement>('.login'));

        loginElements.forEach(input => loginParams[input.dataset.itype as keyof typeof loginParams] = input.value);

        fetch('/login', {
            method: "POST",
            body: JSON.stringify({ data: loginParams }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response: Response) => {
            if (response.ok) navigate('/hubPage');
            else alert('Passwort oder Email sind nicht korrekt!');
        });
    };

    const toggleLoginModal = () => {
        setLoginModalVisible(!loginModalVisible);
        setRegisterModalVisible(false);
    };

    const showTermsModal = () => {
        setTermsModalVisible(true);
    };

    const showRegisterCode = () => {
        setRegisterCodeVisible(true);
    };

    const showRegister = () => {
        setLoginModalVisible(false);
        setTermsModalVisible(false);
        setRegisterModalVisible(!registerModalVisible);
    };

    const closeModals = () => {
        setRegisterModalVisible(false);
        setLoginModalVisible(false);
        setTermsModalVisible(false);
    };

    return (
        <div className="App bg-gradient-to-br from-white to-[#B4D6DD] min-h-screen">
            <header className="App-header">
                <div className="flex fixed top-0 left-0 right-0 z-10 justify-start items-center p-4 bg-black text-white text-lg h-16">
                    <div className="relative group mr-5 ml-5 cursor-default">
                        <span onClick={toggleLoginModal}>
                            Login
                        </span>
                        <span
                            className="absolute -bottom-0 left-0 w-0 h-0.5 bg-white transition-all group-hover:w-full"></span>
                    </div>
                    <div className="relative group mr-5 ml-5 cursor-default">
                        <span onClick={showRegister}>
                            Registrieren
                        </span>
                        <span
                            className="absolute -bottom-0 left-0 w-0 h-0.5 bg-white transition-all group-hover:w-full"></span>
                    </div>
                </div>

                {loginModalVisible && (
                    <div className="min-h-screen flex justify-center items-center">
                        <div className="p-10 max-w-md bg-white shadow-xl rounded-md items-center">
                            <form onSubmit={submitLogin}>
                                <p className="text-2xl text-center mt-6"><b>Anmelden</b></p>
                                <input
                                    type="text"
                                    className="h-11 w-full mt-10 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="email"
                                    placeholder="E-Mail"
                                    required
                                />
                                <input
                                    type="passwort"
                                    className="h-11 w-full mt-8 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="password"
                                    placeholder="Passwort"
                                    required
                                />
                                <div className="text-cyan-800 mt-16"><a>Password vergessen?</a></div>
                                <button
                                    type="submit"
                                    className="w-full mt-2 bg-[#B4E8E5] hover:bg-[#56B6B1] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Login
                                </button>
                            </form>
                        </div>
                    </div>
                )}

                {/* registerCodeModalVisible && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-6 w-fit shadow-md rounded-md">
                            <p className="text-2xl content-center text-center mt-4">Registrierungscode</p>
                            <div className="flex items-center mb-4 mt-3 text-cyan-800 w-fit">
                                <div className={"flex-1 text-3xl p-6"}><input maxLength={1} className={"h-14 w-14 border-b text-center border-black"}/>
                                </div>
                                <div className={"flex-1 text-3xl p-6"}><input maxLength={1} className={"h-14 w-14 border-b text-center border-black"}/>
                                </div>
                                <div className={"flex-1 text-3xl p-6"}><input maxLength={1} className={"h-14 w-14 border-b text-center border-black"}/>
                                </div>
                                <div className={"flex-1 text-3xl p-6"}><input maxLength={1} className={"h-14 w-14 border-b text-center border-black"}/>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="mt-2 w-full bg-[#B4E8E5] hover:bg-[#56B6B1] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={showRegister}
                            >
                                Senden
                            </button>
                        </div>
                    </div>
                )*/}

                {termsModalVisible && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-6 max-w-3xl w-full shadow-md rounded-md">
                            <p className="text-2xl text-center mt-4">Allgemeine Geschäftsbedingungen (AGB)</p>
                            <hr className={"border-black mt-3 mb-2"}/>
                            <div className="overflow-y-scroll mb-4 text-cyan-800" style={{minHeight:550, maxHeight:700}}>
                                {/*todo dummy AGB*/}
                                <p className="text-2xl mt-6">Allgemeine Geschäftsbedingungen</p>
                                <p>Willkommen zu unseren Allgemeinen Geschäftsbedingungen. Wenn Sie hier sind, sind Sie wahrscheinlich genauso verwirrt wie wir, aber keine Sorge, wir werden das gemeinsam durchstehen.</p>

                                <p className="text-2xl mt-6">1. Einleitung</p>
                                <p>Dieser Abschnitt dient dazu, Ihnen klarzumachen, dass wir wichtig sind und Sie diesen Text unbedingt lesen müssen, obwohl keiner von uns das wirklich will.</p>

                                <p className="text-2xl mt-6">2. Vertragsgegenstand</p>
                                <p>Hier geht es um das, was wir tun und was Sie von uns erwarten können. Genau genommen wissen wir das selbst nicht so genau, aber das klingt professionell.</p>

                                <p className="text-2xl mt-6">3. Vertragsabschluss</p>
                                <p>Sie klicken, wir liefern. Es ist so einfach. Warum machen wir das hier überhaupt kompliziert? Vielleicht, weil wir es müssen.</p>

                                <p className="text-2xl mt-6">4. Preise und Zahlungsbedingungen</p>
                                <p>Alles kostet Geld, selbst das Lesen dieser AGB kostet Sie wertvolle Zeit. Zahlen Sie einfach und beschweren Sie sich nicht.</p>

                                <p className="text-2xl mt-6">5. Lieferung und Lieferzeit</p>
                                <p>Wir versprechen, dass wir irgendwann liefern. Ob das in einer Stunde oder einem Jahr ist, hängt vom Wetter und unserer Laune ab.</p>

                                <p className="text-2xl mt-6">6. Widerrufsrecht</p>
                                <p>Wenn Sie es sich anders überlegen, können Sie uns das gerne mitteilen. Wir werden das wahrscheinlich ignorieren, aber fühlen Sie sich frei, es zu versuchen.</p>

                                <p className="text-2xl mt-6">7. Gewährleistung und Haftung</p>
                                <p>Wenn etwas schiefgeht, ist es wahrscheinlich nicht unsere Schuld. Selbst wenn es unsere Schuld ist, behaupten wir einfach das Gegenteil.</p>

                                <p className="text-2xl mt-6">8. Datenschutz</p>
                                <p>Ihre Daten sind bei uns sicher. Außer natürlich, wenn wir sie verlieren oder jemand sie hackt. Dann hatten Sie einfach Pech.</p>

                                <p className="text-2xl mt-6">9. Schlussbestimmungen</p>
                                <p>Dies ist der Abschnitt, den niemand liest. Es geht darum, dass alles, was oben steht, tatsächlich Sinn ergibt. Vertrauen Sie uns einfach.</p>

                                <p className="text-2xl mt-6">10. Kontakt</p>
                                <p>Wenn Sie uns erreichen möchten, schicken Sie uns eine E-Mail. Wir werden wahrscheinlich nicht antworten, aber wir freuen uns über die Post.</p>

                            </div>
                            <button
                                type="button"
                                className="mt-2 w-full bg-[#B4E8E5] hover:bg-[#56B6B1] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={showRegister}
                            >
                                Akzeptieren und weiter
                            </button>
                        </div>
                    </div>
                )}

                {registerModalVisible && (
                    <div className="min-h-screen flex justify-center items-center">
                        <div className="p-6 max-w-md w-full bg-white shadow-md rounded-md">
                            <form onSubmit={register}>
                                <p className="text-2xl text-center mt-6">Registrieren</p>
                                <input
                                    type="text"
                                    className="h-11 w-full mt-10 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="surname"
                                    placeholder="Vorname"
                                    required
                                />
                                <input
                                    type="text"
                                    className="h-11 w-full mt-10 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="lastname"
                                    placeholder="Nachname"
                                    required
                                />
                                <input
                                    type="email"
                                    className="h-11 w-full mt-10 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="email"
                                    placeholder="Email"
                                    required
                                />
                                <input
                                    type="text"
                                    className="h-11 w-full mt-10 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="company"
                                    placeholder="Unternehmen"
                                />
                                <input
                                    type="password"
                                    className="h-11 w-full mt-10 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="password"
                                    placeholder="Passwort"
                                    required
                                />
                                <input
                                    type="password"
                                    className="h-11 w-full mt-10 login border-b border-black appearance-none py-2 px-3 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                    data-itype="passwordConfirm"
                                    placeholder="Passwort bestätigen"
                                    required
                                />
                                <div className="text-cyan-800 mt-14"><input
                                    className={"mr-2 accent-cyan-700"}
                                    type="checkbox" required={true}
                                    onClick={(e) => setTermsAccepted(true)}
                                    />
                                    <span>Ich stimme den <a onClick={showTermsModal} className="cursor-pointer text-cyan-800 font-bold hover:underline">Geschäftsbedingungen</a> zu.</span></div>
                                <button
                                    type="submit"
                                    className="mt-2 w-full bg-[#B4E8E5] hover:bg-[#56B6B1] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Registrieren
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </header>
            <span className="absolute inset-x-0 bottom-0 text-xs text-center">
                © 2024 Vektrus. Alle Rechte vorbehalten. | <Link to="/datenschutz" className="hover:underline">Datenschutz</Link> | <Link to="/impressum" className="hover:underline">Impressum</Link>
            </span>
        </div>
    );
}
