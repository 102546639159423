import React from 'react';
import {Sidebar} from "./sidebar";
import {ChatHistory} from "./chatHistory";
import {Chat} from "./chat";
import { store } from './store';


const ChatPage: React.FC = () => {
    // loading spinner blabla
    return (
        <>
            <div className="flex flex-col h-screen overflow-hidden bg-gradient-to-br from-white to-[#B4D6DD]">
                <div className="flex row h-full">
                    <Sidebar />
                    <ChatHistory />
                    <Chat />
                </div>
            </div>
        </>
    );
}

export default ChatPage;
